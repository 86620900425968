import React from 'react'

import Clipboard from '../Clipboard'

const jobsSiteUrl = process.env.GATSBY_JOBS_SITE_URL || ''

const ActivationLink = ({ profile }) => (
  <Clipboard
    text={`${jobsSiteUrl}/activate/?token=${encodeURIComponent(
      profile.token
    )}&email=${encodeURIComponent(profile.email)}`}
  >
    <span>Copy Activation Link</span>
  </Clipboard>
)

export default ActivationLink
