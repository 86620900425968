import React from 'react'

import { Button } from 'components/Button'
import { FormItem } from 'components/Form'
import { StickyBox } from 'components/StickyBox'

import { stickyBoxStyles } from './StickyFormActions.module.css'

type StickyFormActionsProps = {
  onCancel: () => void
  onSave: () => void
}

const StickyFormActions = ({ onCancel, onSave }: StickyFormActionsProps) => (
  <StickyBox className={stickyBoxStyles} offsetTop={0} offsetBottom={20}>
    <FormItem>
      <Button
        type="primary"
        htmlType="button"
        onClick={onSave}
        data-test="btn-save-profile"
      >
        Save
      </Button>
      <Button type="link" onClick={onCancel}>
        Cancel
      </Button>
    </FormItem>
  </StickyBox>
)

export default StickyFormActions
