import React from 'react'

import { AssignedRoleCandidatesContainer } from '../containers/AssignedRoleCandidatesContainer'
import { AssignedRolesContainer } from '../containers/AssignedRolesContainer'
import { CreateProfileContainer } from '../containers/CreateProfileContainer'

import { useUser } from 'redesign/hooks/useUser'

import { dashboardPageStyles } from './DashboardPage.module.css'

const DashboardPage = () => {
  const { data: user } = useUser()

  return (
    <section className={dashboardPageStyles}>
      <p title={user.provider}>
        Signed in as {user.email} ({user.role.name})
      </p>
      <CreateProfileContainer />
      <AssignedRolesContainer />
      <AssignedRoleCandidatesContainer />
    </section>
  )
}

export default DashboardPage
