import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const getUser = async token => {
  try {
    const response = await api.get('/users/me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const listUsers = async params => {
  try {
    const response = await api.get('/users', params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
