import React from 'react'

import { useMutation } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import { Link } from 'gatsby'

import { message } from 'components/Message'

import {
  createProfile as createProfileService,
  fetchProfile as fetchProfileService
} from 'services/profile'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Profile from 'redesign/types/Profile'

const useCreateProfile = () => {
  // Post
  const createMutation = useMutation({
    mutationFn: createProfileService,
    onSuccess: () => {
      message.success('Profile created')
    },
    onError: async (err: AxiosError, { email }) => {
      const isAlreadyTakenEmailValidationError =
        err?.response?.data.message === 'ValidationError' &&
        Array.isArray(err.response.data.data?.email)

      if (isAlreadyTakenEmailValidationError) {
        const { data } = await fetchProfileService({
          params: {
            email
          }
        })
        return message.error(
          <>
            A profile with this email address already exists. Click{' '}
            <Link to={`/profile?id=${data[0]?.id}`}>here</Link> to view the
            profile.
          </>
        )
      }

      message.error('Create Profile failed')
    }
  })

  const createProfile = (values: Profile, callbacks?: MutationOptions) => {
    createMutation.mutate(values, callbacks)
  }

  return {
    createProfile
  }
}

export default useCreateProfile
