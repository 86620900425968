import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { navigate } from 'gatsby'

import { getUser } from '../../../services/users'

import type { IUseUserReturn } from './useUser.types'

const useUser = (): IUseUserReturn => {
  const token =
    typeof window !== 'undefined' && window.localStorage
      ? localStorage.authToken
      : undefined

  const { data: userData } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUser(token)
  })
  const data = useMemo(() => userData?.data, [userData])

  const signOut = () => {
    localStorage.removeItem('authToken')
    delete axios.defaults.headers.common.Authorization
    navigate('/login')
  }

  return {
    data,
    signOut
  }
}
export default useUser
