import React from 'react'

import { Link } from 'gatsby'

import ActivationLink from 'components/ActivationLink'

import type Profile from 'redesign/types/Profile'

import { linkStyles } from './CreatedProfilesList.module.css'

type CreatedProfilesListProps = {
  profiles: Profile[]
}

const CreatedProfilesList = ({ profiles }: CreatedProfilesListProps) => (
  <ul>
    {profiles.map(profile => (
      <li key={profile.id}>
        <Link className={linkStyles} to={`/profile?id=${profile.id}`}>
          {profile.fullName || profile.email}
        </Link>
        <ActivationLink profile={profile} />
      </li>
    ))}
  </ul>
)

export default CreatedProfilesList
