import React from 'react'

import { useLocation } from '@reach/router'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'
import axios from 'axios'
import { Link } from 'gatsby'

import LoginForm from 'pages/login/LoginForm'

import NetworkErrorDisplay from '../../../components/NetworkErrorDisplay'
import { Spin } from '../../../components/Spin'

import LoginWithGoogle from 'components/Auth/LoginWithGoogle'

import { getUser } from '../../../services/users'

import { useError } from '../../../hooks/useError'

import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'

import { isNetworkError } from 'utils/helpers'

import type User from 'redesign/types/User'

import type {
  IAuthProviderLocationState,
  IAuthProviderProps
} from './AuthProvider.types'

import { errorStyles, loginFormStyles } from './AuthProvider.module.css'

const AuthProvider = ({ children }: IAuthProviderProps) => {
  const location = useLocation()
  const token =
    typeof window !== 'undefined' && window.localStorage
      ? localStorage.authToken
      : undefined
  const { data: features } = useFeatureFlags()
  const { error: validationError } = useError()
  const queryClient = useQueryClient()

  const { status, error } = useQuery<AxiosResponse, AxiosError>({
    queryKey: ['user'],
    queryFn: () => getUser(token),
    onSuccess: () =>
      (axios.defaults.headers.common.Authorization = `Bearer ${token}`),
    retry: 2,
    refetchOnWindowFocus: false
  })

  if (status === 'loading') {
    return (
      <div className="spinnerContainer">
        <Spin tip="Loading..." size="large" />
      </div>
    )
  }

  if (status === 'error') {
    if (isNetworkError(error.message)) {
      return <NetworkErrorDisplay errorStatus={error.request.status} />
    }

    const isUnauthorizedAccess = error.response?.status === 401
    if (isUnauthorizedAccess) {
      const redirectTo = `${location.pathname}${location.search}`
      const isGoogleLogin =
        features?.OPS_GOOGLE_LOGIN &&
        !(location.state as IAuthProviderLocationState)?.loginForm

      return (
        <div className={loginFormStyles}>
          {validationError && (
            <div className={errorStyles}>{validationError}</div>
          )}

          {isGoogleLogin
            ? (<LoginWithGoogle />)
            : (
              <LoginForm
                onSuccess={async (data: User) => {
                // This await is important to ensure query data
                  await queryClient.invalidateQueries({ queryKey: ['user'] })
                  queryClient.setQueryData(['user'], { data })
                }}
              />
            )}

          <div className="login-with-provider">
            <Link
              to={redirectTo}
              state={{
                loginForm: isGoogleLogin
              }}
            >
              Login Using {isGoogleLogin ? 'Email' : 'Google'}
            </Link>
          </div>
        </div>
      )
    }
  }

  // The query has succeeded at this stage
  return children
}

export default AuthProvider
