import React, { useState } from 'react'

import { CreatedProfilesList } from './components/CreatedProfilesList'
import { ProfileForm } from './components/ProfileForm'

import { Button } from 'components/Button'

import { useCreateProfile } from 'redesign/hooks/useCreateProfile'

import type Profile from 'redesign/types/Profile'

const CreateProfileContainer = () => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const [createdProfiles, setCreatedProfiles] = useState<Profile[]>([])

  const { createProfile } = useCreateProfile()

  const onSuccess = ({ data }: { data: Profile }) => {
    const updatedCreatedProfiles = [data, ...createdProfiles]
    setCreatedProfiles(updatedCreatedProfiles)
    setEditMode(false)
  }

  const onSave = (formValues: Profile) => {
    createProfile(formValues, { onSuccess })
  }

  const onCancel = () => {
    setEditMode(false)
  }

  if (editMode) {
    return <ProfileForm onSave={onSave} onCancel={onCancel} />
  }

  const enableEditMode = () => {
    setEditMode(true)
  }

  return (
    <section>
      <h2>Create Profile</h2>
      <Button onClick={enableEditMode}>Create</Button>
      <CreatedProfilesList profiles={createdProfiles} />
    </section>
  )
}

export default CreateProfileContainer
