import React from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { Layout } from '../Layout'
import { AuthProvider } from 'redesign/providers/AuthProvider'

import type { MenuKey } from 'redesign/types/Menu'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity
    }
  }
})

type RootComponentProps = {
  children: React.ReactNode,
  menuSelectedKeys?: MenuKey[]
  showHeaderMenu?: boolean
}

const RootComponent = ({ children, menuSelectedKeys, showHeaderMenu = true }: RootComponentProps) => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <Layout menuSelectedKeys={menuSelectedKeys} showHeaderMenu={showHeaderMenu}>
        {children}
      </Layout>
    </AuthProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)

export default RootComponent
