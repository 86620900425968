import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import type { PartnerRoleFilters } from 'redesign/modules/Partners/containers/PartnerRoles/components/PartnerRolesTable/PartnerRolesTable.types'

import { countPartnerRoles } from 'services/partnerRoles'

import type { usePartnerRolesCountReturn } from './usePartnerRolesCount.types'

const usePartnerRolesCount = (
  params: PartnerRoleFilters
): usePartnerRolesCountReturn => {
  const { data: partnerRolesCount } = useQuery({
    queryKey: ['partnerRolesCount', params],
    queryFn: () => countPartnerRoles({ params })
  })
  const data: number = useMemo(
    () => partnerRolesCount?.data,
    [partnerRolesCount]
  )

  return {
    data
  }
}
export default usePartnerRolesCount
