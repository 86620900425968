import React, { useEffect, useState } from 'react'

import type { CountryCode, Timezone } from 'countries-and-timezones'
import { getTimezonesForCountry } from 'countries-and-timezones'

import { useForm } from '../../../../../../../hooks/useForm'
import { StickyFormActions } from '../StickyFormActions'

import { Col } from 'components/Col'
import { Form, FormItem } from 'components/Form'
import { Input } from 'components/Input'
import { LabeledValue } from 'components/LabeledValue'
import { MarketingCreateForm } from 'components/Profile/Marketing/MarketingCreateForm'
import { RunwayProfileForm } from 'components/Profile/Runways/RunwayProfileForm'
import { Row } from 'components/Row'
import { Select, SelectOption } from 'components/Select'
import { Switch } from 'components/Switch'

import { useCountries } from 'redesign/hooks/useCountries'

import { prepareLocationSelectValue } from 'utils/timezone-column'

import { SOURCED_FROM_OPTIONS, SOURCE_OPTIONS } from 'redesign/utils/constants'
import { arrayUniqueByKey } from 'redesign/utils/helpers'

import type Profile from 'redesign/types/Profile'

type ProfileFormProps = {
  onCancel: () => void
  onSave: (formValues: Profile) => void
}

const ProfileForm = ({
  onCancel: onCancelCallback,
  onSave: onSaveCallback
}: ProfileFormProps) => {
  const [form] = useForm()
  const { data: countries, isLoading: isLoadingCountries } = useCountries()
  const [countryId, setCountryId] = useState<number | undefined>()
  const [timezones, setTimezones] = useState<Timezone[]>()

  useEffect(() => {
    const selectedCountry = countries?.find(countryItem => countryItem?.id === countryId)
    if (selectedCountry) {
      const { codeAlpha2 } = selectedCountry
      if (codeAlpha2) {
        const countryTimezones = getTimezonesForCountry(codeAlpha2 as CountryCode)
        const countryUniqueTimezones = arrayUniqueByKey<Timezone>(
          countryTimezones,
          'utcOffset'
        )?.sort((a, b) => b.utcOffset - a.utcOffset)
        setTimezones(countryUniqueTimezones)
        const [first] = countryTimezones
        form.setFieldValue('timezoneOffset', first?.utcOffset)
      }
    }
  }, [countries, countryId, form])

  const handleCountryChange = (countryId: number) => {
    setCountryId(countryId)
  }

  const onSave = async () => {
    try {
      // Throw exception if any required field is empty
      await form.validateFields()
    } catch (e) {
      return
    }

    const formValues = form.getFieldsValue()

    if (formValues.location) {
      const { country, timezone } = prepareLocationSelectValue(
        formValues.location
      )
      return onSaveCallback({
        country,
        timezoneOffset: timezone,
        ...formValues
      })
    }

    onSaveCallback(formValues)
  }

  const onCancel = () => {
    onCancelCallback()
  }

  return (
    <Form data-testid="profile-form" form={form} layout="vertical">
      <StickyFormActions onSave={onSave} onCancel={onCancel} />

      <FormItem name="fullName" label="Full Name" rules={[{ required: true }]}>
        <Input />
      </FormItem>

      <FormItem
        name="email"
        label="Email"
        rules={[{ required: true, type: 'email' }]}
      >
        <Input />
      </FormItem>

      <FormItem
        name="xteamEmail"
        label="X-Team Email"
        rules={[{ type: 'email', required: false }]}
      >
        <Input />
      </FormItem>

      <FormItem
        name="githubAccount"
        label="Github Account"
        rules={[{ required: false }]}
      >
        <Input />
      </FormItem>

      <FormItem
        name="linkedinAccount"
        label="LinkedIn Account"
        rules={[{ required: false }]}
      >
        <Input />
      </FormItem>

      <FormItem
        name="stackoverflowAccount"
        label="Other / Portfolio Link"
        rules={[{ required: false }]}
      >
        <Input />
      </FormItem>

      <Row gutter={16}>
        <Col span={12}>
          <FormItem name="country" label="Country">
            <Select
              showSearch
              filterOption={(input, option) => ((option?.label?.toString()?.toLocaleLowerCase() ?? ''))?.includes(input?.toLocaleLowerCase())}
              data-testid="select-profile-country"
              disabled={isLoadingCountries}
              onChange={handleCountryChange}
              value={countryId}
              aria-autocomplete="none"
            >
              {countries?.map(({ id, name, codeAlpha2 }) => (
                <SelectOption
                  key={id}
                  value={id}
                  data-testid={`select-profile-country-option-${codeAlpha2}`}
                  label={name}
                >
                  {name}
                </SelectOption>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name="timezoneOffset" label="Timezone">
            <Select showSearch>{timezones?.map(timezone => (
              <SelectOption
                key={timezone.name}
                value={timezone.utcOffset}
                data-testid="select-profile-timezoneOffset-option"
              >
                {timezone.utcOffsetStr}
              </SelectOption>
            ))}
            </Select>
          </FormItem>
        </Col>
      </Row>

      <FormItem
        name="about"
        label="About (by Applicant)"
        rules={[{ required: false }]}
      >
        <Input.TextArea autoSize />
      </FormItem>

      <FormItem
        name="summary"
        label="Summary (by Cavalry)"
        rules={[{ required: false }]}
      >
        <Input.TextArea autoSize />
      </FormItem>

      <MarketingCreateForm />

      <LabeledValue
        label="Sourcing"
        value={
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <FormItem
                name="source"
                label="Source"
                rules={[{ required: true }]}
              >
                <Select>
                  {Object.entries(SOURCE_OPTIONS).map(([label, value]) => (
                    <SelectOption key={value} value={label}>
                      {value}
                    </SelectOption>
                  ))}
                </Select>
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem
                name="sourcedFrom"
                label="Sourced from"
                rules={[{ required: true }]}
              >
                <Select>
                  {Object.entries(SOURCED_FROM_OPTIONS).map(
                    ([label, value]) => (
                      <SelectOption key={value} value={label}>
                        {value}
                      </SelectOption>
                    )
                  )}
                </Select>
              </FormItem>
            </Col>
          </Row>
        }
      />

      <FormItem name="referral" label="Referral">
        <Input maxLength={255} />
      </FormItem>
      <FormItem
        name="subscribed"
        valuePropName="checked"
        label="Subscribed to Emails?"
      >
        <Switch />
      </FormItem>

      <RunwayProfileForm />
    </Form>
  )
}

export default ProfileForm
