import React, { useCallback, useState } from 'react'

import type { SorterResult } from 'antd/es/table/interface'

import type { HandleRoleCandidateAssigneeChangeType } from 'redesign/containers/RoleCandidatesTableContainer/RoleCandidatesTableContainer.types'

import type {
  TablePaginationConfig,
  TableProps,
  Pagination
} from 'components/Table'

import { PartnerRolesTable } from 'redesign/modules/Partners/containers/PartnerRoles/components/PartnerRolesTable/PartnerRolesTable'
import type { PartnerRoleFilters } from 'redesign/modules/Partners/containers/PartnerRoles/components/PartnerRolesTable/PartnerRolesTable.types'

import { usePartnerRoles } from 'redesign/hooks/usePartnerRoles'
import { usePartnerRolesConfig } from 'redesign/hooks/usePartnerRolesConfig'
import { usePartnerRolesCount } from 'redesign/hooks/usePartnerRolesCount'
import { useUser } from 'redesign/hooks/useUser'

import { removeNullValues } from 'redesign/utils/objectUtils'

import type PartnerRole from 'redesign/types/PartnerRole'

import { containerStyles } from './AssignedRoles.module.css'

const AssignedRolesContainer = () => {
  const { data: authData } = useUser()
  const { data: partnerRolesConfig } = usePartnerRolesConfig()
  const [assignees, setAssignees] = useState([authData.id])
  const [tableFilters, setTableFilters] = useState({})
  const [nameFilter, setNameFilter] = useState('')
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)

  const {
    data: roles,
    isLoading,
    addRoleAssigneesMutation,
    removeRoleAssigneesMutation
  } = usePartnerRoles({
    params: {
      _start: (page - 1) * limit,
      _limit: limit,
      active: true,
      assignees,
      ...tableFilters,
      '_where[_and][0][assignees_in]': assignees
    }
  })

  const { data: partnerRolesCount } = usePartnerRolesCount({
    ...tableFilters,
    active: true,
    assignees,
    ...tableFilters,
    '_where[_and][0][assignees_in]': assignees
  })

  const getActiveFilters = ({
    filters,
    sort
  }: {
    filters: Partial<PartnerRoleFilters>
    sort?: string | null
  }) => {
    const activeFilters: PartnerRoleFilters = {
      _sort: sort,
      assignees: filters?.assignees ?? null,
      activeAssignees: filters?.activeAssignees ?? null,
      '_where[_or][0][profile.fullName_contains]':
        (filters?.profile as string) ?? null,
      '_where[_or][1][profile.email_contains]':
        (filters?.profile as string) ?? null,
      '_where[_or][2][profile.xteamEmail_contains]':
        (filters?.profile as string) ?? null,
      '_where[_and][3][activeAssignees_in]': filters?.activeAssignees,
      '_where[_or][0][name_contains]': (filters?.name as string) ?? null
    }
    const formattedResult = removeNullValues(activeFilters)
    return formattedResult
  }

  const onTableChange: TableProps<PartnerRole>['onChange'] = useCallback(
    (
      _: TablePaginationConfig,
      filters: PartnerRoleFilters,
      sorter: SorterResult<PartnerRole> | SorterResult<PartnerRole>[]
    ) => {
      if (Array.isArray(sorter)) {
        return
      }
      const order = sorter.order === 'ascend' ? 'ASC' : 'DESC'
      const sort = sorter.column
        ? `${sorter.column.dataIndex ?? sorter.column.key}:${order}`
        : null
      setNameFilter(filters?.name || '')
      const activeFilters: PartnerRoleFilters = getActiveFilters({
        filters,
        sort
      })
      setTableFilters(activeFilters)
    },
    []
  )

  const handleAssigneeChange =
    (roleId: number) =>
      ({ userId, isAssigned }: HandleRoleCandidateAssigneeChangeType) => {
        const functionToCall = isAssigned
          ? removeRoleAssigneesMutation
          : addRoleAssigneesMutation
        functionToCall({ roleId, assignees: [userId] })
      }

  const handlePagination = ({ page, pageSize }: Pagination) => {
    setPage(page)
    setLimit(pageSize)
  }

  return (
    <section className={containerStyles}>
      <h2>Assigned Roles</h2>
      <PartnerRolesTable
        roles={roles}
        isLoading={isLoading}
        handleAssigneeChange={handleAssigneeChange}
        partnerRolesConfig={partnerRolesConfig}
        isRenderedOnDashboard
        setAssignees={setAssignees}
        roleAssignees={assignees}
        onTableChange={onTableChange}
        nameFilter={nameFilter}
        total={partnerRolesCount}
        page={page}
        limit={limit}
        handlePagination={handlePagination}
      />
    </section>
  )
}

export default AssignedRolesContainer
