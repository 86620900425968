import React from 'react'

import {
  GithubIcon,
  LinkedinIcon,
  OtherPortfolioIcon,
  OtherSocialNetworkIcon,
  StackoverflowIcon,
  TrelloIcon
} from '../Svg'
import type { SvgIconProps } from '../Svg/Svg.types'

import {
  generateSocialAccountHref,
  getDomainName,
  getSocialAccountUsernameAndType
} from '../../utils/helpers'

import type { IconType, SocialIconProps } from './SocialIcon.types'

export const iconByType = {
  github: (props: SvgIconProps) => <GithubIcon {...props} />,
  linkedin: (props: SvgIconProps) => <LinkedinIcon {...props} />,
  stackoverflow: (props: SvgIconProps) => <StackoverflowIcon {...props} />,
  trello: (props: SvgIconProps) => <TrelloIcon {...props} />,
  other: (props: SvgIconProps) => <OtherSocialNetworkIcon {...props} />,
  default: (props: SvgIconProps) => <OtherPortfolioIcon {...props} />
}

const SocialIcon = ({ type, value, style, ...props }: SocialIconProps) => {
  const { accountName, domainType } = value && value?.length > 0
    ? getSocialAccountUsernameAndType(value)
    : { accountName: '', domainType: '' }
  const iconType: IconType = type || (domainType as IconType) || 'default'
  let accountHref = accountName
    ? generateSocialAccountHref(accountName, type) || value
    : undefined

  // If the website protocol is not defined, the link will point to "localhost" automatically
  if (accountHref && !String(accountHref).startsWith('http')) {
    accountHref = '//' + accountHref
  }

  const icon = iconByType[iconType] || iconByType.default

  return (
    <a
      target="_blank"
      rel="noreferrer noopener"
      href={accountHref}
      onClick={e => e.stopPropagation()}
      data-testid={`social-icon-${iconType}`}
    >
      {icon({
        fill: getDomainName(accountHref) ? 'white' : 'hsla(0, 0%, 100%, .5)',
        style,
        ...props
      })}
    </a>
  )
}

export default SocialIcon
