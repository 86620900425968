import React from 'react'

import { App } from 'antd'

import { AuthConsumer } from '../providers/AuthProvider'
import { RootComponent } from 'redesign/app/RootComponent'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

import { DashboardPage as DashboardPageComponent } from 'redesign/modules/Dashboard'

const DashboardPage = () => (
  <App>
    <Layout>
      <SEO title="Home" />
      <AuthConsumer>
        {({ isLoading, data }) => {
          if (isLoading) {
            return <div>Loading...</div>
          }

          if (!data) {
            return <div>Not logged in</div>
          }

          return (
            <RootComponent showHeaderMenu={false}>
              <DashboardPageComponent />
            </RootComponent>
          )
        }}
      </AuthConsumer>
    </Layout>
  </App>
)

export default DashboardPage
