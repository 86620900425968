import React from 'react'

import { Col } from '../../Col'
import { FormItem } from '../../Form'
import { Input } from '../../Input'
import { InputNumber } from '../../InputNumber'
import LabeledValue from '../../LabeledValue'
import { Row } from '../../Row'

export const RunwayProfileForm = () => (
  <LabeledValue
    label="Internal Projects Details"
    value={
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <>
            <FormItem name="benchStatus" label="Status">
              <Input maxLength="255" />
            </FormItem>

            <FormItem name="benchSummary" label="Summary">
              <Input maxLength="255" />
            </FormItem>

            <FormItem name="xhqRate" label="Developer's XHQ rate">
              <InputNumber min={0} />
            </FormItem>
          </>
        </Col>
      </Row>
    }
  />
)
