import React from 'react'

import { Col } from 'components/Col'
import { FormItem } from 'components/Form'
import { Input } from 'components/Input'
import LabeledValue from 'components/LabeledValue'
import { Row } from 'components/Row'

export const MarketingCreateForm = () => (
  <LabeledValue
    label="Marketing"
    value={
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <FormItem name="utmSource" label="Source">
            <Input />
          </FormItem>

          <FormItem name="utmMedium" label="UTM Medium">
            <Input />
          </FormItem>

          <FormItem name="utmCampaign" label="UTM Campaign">
            <Input />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem name="utmContent" label="UTM Content">
            <Input />
          </FormItem>

          <FormItem name="utmTerm" label="UTM Term">
            <Input />
          </FormItem>

          <FormItem name="utmReferral" label="UTM Referral">
            <Input />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem name="hearAbout" label="Where Did You Hear About Us?">
            <Input />
          </FormItem>
        </Col>
      </Row>
    }
  />
)
