import React from 'react'

import Highlighter from 'react-highlight-words'

import { DropdownFilter } from '../DropdownFilter'
import { Input } from '../Input'

export function SearchFilterDropdown (props) {
  const {
    placeholder,
    filterKey,
    forwardedRef,
    handleSearch,
    handleReset,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  } = props
  return (
    <DropdownFilter
      handleFilter={() => handleSearch(selectedKeys, confirm, filterKey)}
      handleReset={() => handleReset(clearFilters, confirm)}
    >
      <Input
        ref={forwardedRef}
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={e => {
          e.preventDefault()
          e.stopPropagation()
          handleSearch(selectedKeys, confirm, filterKey)
        }}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
    </DropdownFilter>
  )
}

const highlightTag =
  attributeKey =>
    ({ children }) =>
      (
        <mark style={{ backgroundColor: '#FFC069', padding: 0 }}>
          <span data-testid={`highlight-${attributeKey}`}>{children}</span>
        </mark>
      )

export function useSearchFilter () {
  const [search, setSearch] = React.useState({})
  const filterInputEl = React.useRef(null)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearch({
      text: selectedKeys[0],
      column: dataIndex
    })
  }

  const handleReset = (clearFilters, confirm) => {
    clearFilters()
    setSearch(state => ({
      ...state,
      text: ''
    }))
    confirm()
  }

  const createSearchableColumn = React.useCallback(
    attr => {
      const { placeholder, render } = attr

      delete attr.placeholder
      delete attr.render

      const filterDropdown = props => (
        <SearchFilterDropdown
          {...props}
          forwardedRef={filterInputEl}
          placeholder={placeholder}
          filterKey={attr.key}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
      )

      return {
        ...attr,

        render: value =>
          render(value, props =>
            search.column === attr.key
              ? (
                <Highlighter
                  searchWords={[search.text]}
                  autoEscape
                  textToHighlight={props.text ? props.text.toString() : ''}
                  highlightTag={highlightTag(attr.key)}
                />
              )
              : (
                props.text
              )
          ),

        filterDropdown,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => filterInputEl.current.select(), 100)
          }
        }
      }
    },
    [search]
  )

  return {
    search,
    createSearchableColumn
  }
}
