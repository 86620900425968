import { useMutation, useQueryClient } from '@tanstack/react-query'
import { message } from 'antd'
import type { AxiosError, AxiosResponse } from 'axios'

import { updateJobApplication as updateJobApplicationService } from 'services/jobApplications'

import type {
  UseUpdateJobApplicationReturn,
  UpdateJobApplicationValueParams
} from './useUpdateJobApplication.types'

const useUpdateJobApplication = (): UseUpdateJobApplicationReturn => {
  const queryClient = useQueryClient()

  const updateMutation = useMutation<
    AxiosResponse,
    AxiosError,
    UpdateJobApplicationValueParams
  >({
    mutationFn: ({ jobApplicationId, values }) =>
      updateJobApplicationService(jobApplicationId, values),
    onSuccess: () => {
      message.success('Job application updated')
      queryClient.invalidateQueries({ queryKey: ['jobApplications'] })
      queryClient.invalidateQueries({ queryKey: ['advancedSearch'] })
    },
    onError: () => {
      message.error('Update job application failed')
    }
  })

  const updateJobApplication = (values: UpdateJobApplicationValueParams) =>
    updateMutation.mutate(values)

  return {
    updateJobApplication,
    isLoading: updateMutation.isLoading
  }
}
export default useUpdateJobApplication
