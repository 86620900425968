import React from 'react'

import { RoleCandidatesTableContainer } from 'redesign/containers/RoleCandidatesTableContainer'
import type { RoleCandidateFilters } from 'redesign/containers/RoleCandidatesTableContainer/components/RoleCandidatesTable/RoleCandidatesTable.types'

import { useFeatureFlags } from 'redesign/hooks/useFeatureFlags'
import { useUser } from 'redesign/hooks/useUser'

const AssignedRoleCandidatesContainer = () => {
  const { data: user } = useUser()
  const { data: featureFlags } = useFeatureFlags()
  const shouldDisplayRoleAssignees = Boolean(
    featureFlags?.ROLE_AND_ROLE_CANDIDATE_ASSIGNEES
  )

  const tableFilters: RoleCandidateFilters = {
    ...(shouldDisplayRoleAssignees
      ? { '_where[_and][3][activeAssignees_in]': [user.id] }
      : { assignee: user.id }),
    '_where[_and][0][reservedAt_null]': false,
    '_where[_and][0][closedAt_null]': true,
    '_where[_and][1][bookedAt_null]': true
  }
  const isBizdev = user.role.name === 'Bizdev'

  return (
    <section>
      <h2>Assigned Role Candidates</h2>
      <RoleCandidatesTableContainer
        showActionColumn
        shouldDisplayRole={true}
        filters={tableFilters}
        isBizdev={isBizdev}
      />
    </section>
  )
}

export default AssignedRoleCandidatesContainer
