import React from 'react'

import { Link } from 'gatsby'
import moment from 'moment'

import { EnabledIcon } from 'components/EnabledIcon'
import { ListFilterDropdown } from 'components/ListFilter'
import { Pagination as PaginationComponent } from 'components/Pagination'
import { useSearchFilter } from 'components/SearchFilter'
import { SocialIcon } from 'components/SocialIcon'
import type {
  TableProps,
  ColumnsType,
  SortOrder,
  Pagination
} from 'components/Table'
import { Table } from 'components/Table'
import { Tag } from 'components/Tag'

import { Assignees } from 'redesign/components/Assignees'

import type { HandleAssigneeChangeType } from 'redesign/modules/Role/page/Role.types'

import type { PartnerRoleConfigType } from 'redesign/hooks/usePartnerRolesConfig/usePartnerRolesConfig.types'
import { useUser } from 'redesign/hooks/useUser'
import { useUserList } from 'redesign/hooks/useUserList'

import { PARTNER_ROLE_STAGE_INFO } from 'utils/constants'
import { compareStrings, sortByField } from 'utils/helpers'

import type PartnerRole from 'redesign/types/PartnerRole'
import type { PartnerRoleStageInfo } from 'redesign/types/PartnerRole'
import type Skill from 'redesign/types/Skill'
import type User from 'redesign/types/User'

import { tableStyles } from './PartnerRolesTable.module.css'

type Props = {
  roles: PartnerRole[]
  isLoading: boolean
  partnerRolesConfig: PartnerRoleConfigType
  handleAssigneeChange: (
    roleId: number
  ) => ({ userId, isAssigned }: HandleAssigneeChangeType) => void
  isRenderedOnDashboard?: boolean
  setAssignees?: React.Dispatch<React.SetStateAction<number[]>>
  roleAssignees?: number[]
  onTableChange?: TableProps<PartnerRole>['onChange']
  nameFilter?: string
  page?: number
  limit?: number
  total?: number
  handlePagination?: ({ page, pageSize }: Pagination) => void
}

export const PartnerRolesTable = ({
  roles,
  isLoading,
  partnerRolesConfig,
  handleAssigneeChange,
  isRenderedOnDashboard = false,
  setAssignees,
  roleAssignees,
  onTableChange,
  nameFilter,
  total,
  page,
  limit,
  handlePagination
}: Props) => {
  const { createSearchableColumn } = useSearchFilter()
  const { usersById } = useUserList()
  const { data: authData } = useUser()

  const columns: ColumnsType<PartnerRole> = [
    createSearchableColumn({
      title: 'Name',
      key: 'name',
      sorter: (a: PartnerRole, b: PartnerRole) =>
        compareStrings(a.name, b.name),
      placeholder: 'Search Role Name',
      onFilter: (value: string, record: PartnerRole) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
      // @TODO(TS migration)
      // @ts-expect-error understand what `Text` represents and type accordingly
      // to help with its props down below
      render: (value: PartnerRole, Text) => (
        <Link to={`/role?id=${value?.id}`}>
          <Text text={value.name} />
        </Link>
      )
    }),
    {
      title: 'Required Skills',
      dataIndex: 'requiredSkills',
      key: 'requiredSkills',
      render: (skills: Skill[]) =>
        skills
          ? skills.map(skill => (
            <Tag key={skill.slug} color="blue">
              {skill.title}
            </Tag>
          ))
          : '',
      sorter: false
    },
    {
      title: 'Partner',
      dataIndex: 'partner',
      key: 'partner',
      render: value => (
        <Link to={`/partners?id=${value?.id}`}>{value?.name}</Link>
      )
    },
    {
      title: 'Role Assignees',
      dataIndex: 'assignees',
      render: (assignees: User[], record: PartnerRole) => (
        <Assignees
          key={record.id}
          assignees={assignees}
          authData={authData}
          handleAssigneeChange={handleAssigneeChange(record.id)}
          shouldUseTableStyles
        />
      ),
      filterMultiple: true,
      onFilter: (value: number, record) =>
        record.assignees?.some(({ id }) => id === value),
      filterDropdown: props => (
        <ListFilterDropdown
          {...props}
          filterKey="assignees"
          filters={[{ text: 'Me', value: authData.id }].concat(
            usersById
              ? Object.values(usersById)
                .filter(user => user.id !== authData.id)
                .sort(sortByField('email'))
                .map(user => ({
                  text: user.email,
                  value: user.id
                }))
              : []
          )}
          filterMultiple={true}
        />
      )
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      defaultSortOrder: 'ascend',
      render: value => {
        const priority = partnerRolesConfig?.priorities?.find(
          p => p.value === value
        )
        if (priority) {
          return `${priority.label} (${priority.details})`
        }
        return ''
      },
      sorter: true
    },
    {
      title: 'Team',
      dataIndex: 'team',
      key: 'team',
      render: value => (value ? value.name : ''),
      sorter: true
    },
    {
      title: 'Trello',
      dataIndex: 'trelloCardId',
      key: 'trelloCardId',
      render: trelloCardId =>
        trelloCardId ? <SocialIcon value={trelloCardId} type="trello" /> : '',
      sorter: true,
      align: 'center'
    },
    {
      title: 'Role Status',
      dataIndex: 'active',
      key: 'active',
      render: value => <EnabledIcon enabled={value} />,
      sorter: true,
      align: 'center'
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: value => moment(value).format('YYYY-MM-DD HH:mm'),
      sorter: (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      defaultSortOrder: 'descend' as SortOrder
    }
  ]

  const dashboardColumns: ColumnsType<PartnerRole> = [
    createSearchableColumn({
      title: 'Role Name',
      key: 'name',
      width: 200,
      sorter: true,
      placeholder: 'Search Role Name',
      onFilter: (value: string, record: PartnerRole) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
      // @TODO(TS migration)
      // @ts-expect-error understand what `Text` represents and type accordingly
      // to help with its props down below
      render: (value: PartnerRole, Text) => (
        <Link to={`/role?id=${value?.id}`}>
          <Text text={value.name} />
        </Link>
      ),
      filteredValue: nameFilter
    }),
    {
      title: 'Partner',
      width: 200,
      key: 'partner.name',
      render: record => (
        <Link to={`/partners?id=${record.partner?.id}`}>
          {record.partner?.name}
        </Link>
      ),
      sorter: true
    },
    {
      title: 'Role Stage',
      dataIndex: 'stage',
      width: 200,
      render: (stage: PartnerRoleStageInfo) => {
        const roleStage = Object.values(PARTNER_ROLE_STAGE_INFO).find(
          stg => stage?.id === stg.id
        )
        return <div>{roleStage ? roleStage.title : 'N/A'}</div>
      },
      sorter: true
    },
    {
      title: 'Role Assignees',
      dataIndex: 'assignees',
      width: 350,
      render: (assignees: User[], record: PartnerRole) => (
        <Assignees
          key={record.id}
          assignees={assignees}
          authData={authData}
          handleAssigneeChange={handleAssigneeChange(record.id)}
          shouldUseTableStyles
        />
      ),
      filterMultiple: true,
      filteredValue: roleAssignees,
      filterDropdown: props => (
        <ListFilterDropdown
          {...props}
          filterKey="assignees"
          onFilterChange={(keys: number[]) => setAssignees?.(keys)}
          filters={[{ text: 'Me', value: authData.id }].concat(
            usersById
              ? Object.values(usersById)
                .filter(user => user.id !== authData.id)
                .sort(sortByField('email'))
                .map(user => ({
                  text: user.email,
                  value: user.id
                }))
              : []
          )}
          filterMultiple={true}
        />
      )
    },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: value => moment(value).format('YYYY-MM-DD HH:mm'),
      sorter: (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      defaultSortOrder: 'descend' as SortOrder
    }
  ]

  const tableColumns = isRenderedOnDashboard ? dashboardColumns : columns

  return (
    <>
      <PaginationComponent
        pageSize={limit}
        current={page}
        onChange={(page: number, pageSize: number) =>
          handlePagination?.({ page, pageSize })
        }
        total={total}
      />
      <Table
        rowKey="id"
        columns={tableColumns}
        dataSource={roles}
        scroll={{
          x: 'max-content'
        }}
        loading={isLoading}
        onChange={onTableChange}
        className={tableStyles}
        pagination={false}
      />
      <PaginationComponent
        pageSize={limit}
        current={page}
        onChange={(page: number, pageSize: number) =>
          handlePagination?.({ page, pageSize })
        }
        total={total}
      />
    </>
  )
}
